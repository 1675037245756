/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import * as React from "react"
import { jsx } from 'theme-ui';
import { graphql } from "gatsby"
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Helmet from 'react-helmet';
import { Flex, Box, Heading, Text } from 'rebass';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import styled from '@emotion/styled/macro';
import strings from './strings.json';

const Stockists = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { location } = props;
  const { title, description } = strings;


  const StockistsGroupBox = styled(Box)``;

  const StockistLink = styled.a`
    color: #212529;
    text-decoration: 'none';

    &:hover, &:focus {
      color: #212529;
      text-decoration: 'none';
    }
  `
  const UnstyledList = styled.ul`
    list-style: none;
    padding-left: 0;
  `

  return (
    <Layout>
      <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        description={description}
        openGraph={{
          url: location.href,
          title: `${title} — ${storeName}`,
          description: description,
          images: [{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false} />
      <Flex flexWrap="wrap" px={2} pt={3} mx="auto">
        <Flex
          width={1}
          px={4}
          py={2}
          flexWrap='wrap'
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box
            sx={{
              display: 'block',
            }}
            pt={1}
          >
            <Breadcrumbs
              productTitle={title}
              separator="/"
            />
          </Box>
          <Box width={1} mb={2}>
            <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
              {title}
            </Heading>
            <Text fontSize={[2, 3]}>{description}</Text>
          </Box>
        </Flex>
        <Flex
          width={1}
          px={4}
          py={2}
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <StockistsGroupBox width={[1, 1 / 2]} mb={2} >
            <StockistLink href="https://erebusstyle.com/collections/too-damn-expensive" target="_blank">
              <h4>Erebus</h4>
              <p>
                https://erebusstyle.com/ <br /> United Kingdom
              </p>
            </StockistLink>

            <br />

            <StockistLink href="https://www.the-clothinglounge.com/collections/too-damn-expensive" target="_blank">
              <h4>The Clothing Lounge</h4>
              <p>
                http://www.the-clothinglounge.com/ <br /> United Kingdom
              </p>
            </StockistLink>
          </StockistsGroupBox>
          <StockistsGroupBox width={[1, 1 / 2]}   mb={2} >

          {/*
            <StockistLink href="https://www.toodamnexpensive.com/" target="_blank">
              <h4>Too Damn Expensive <span style={{color: 'rgb(199, 6, 6)'}}>POP-UP</span></h4>
              <p>
                Akersgata 67 - 0180 Oslo - Norway
              </p>
            </StockistLink>
            <Box>
              <UnstyledList >
                <li>Mon, Tue, Wed, Fri <span >10-18</span></li>
                <li>Thursday <span >10-19</span></li>
                <li>Saturday <span >11-18</span></li>
              </UnstyledList>
            </Box>
            <br />
          */}

            <StockistLink href="https://8lackofficial.com/" target="_blank">

              <h4>8LACK OFFICIAL</h4>
              <p>
                Le Carrefour - 3003 Boulevard - Laval - H7T 1C7 Quebec - Canada
              </p>
            </StockistLink>

            <br />

            <StockistLink href="https://www.instagram.com/miss_nicola_boutique/" target="_blank">
              <h4>Miss Nicola</h4>
              <p>
                122 Charles Street - Launceston Tas 7250 - Australia
              </p>
            </StockistLink>

            <br />

            <h4>Nannini Group</h4>
            <p>Bermuda</p>

            <br />

            <StockistLink href="http://www.splendeurs18.com/" target="_blank">
              <h4>Splendeurs 18</h4>
              <p>Bauernmarkt 18 - A-1010 Wien - Austria</p>
            </StockistLink>

            <br />

            <StockistLink href="https://www.facebook.com/530343067071824/photos/pcb.1054532807986178/1054532214652904/?type=3&theater" target="_blank">
              <h4>Urban Concept</h4>
              <p>via nazario Sauro 35 - Parma - Italy</p>
            </StockistLink>
          </StockistsGroupBox>
        </Flex>
      </Flex>
    </Layout>
  );
}

export default Stockists;

export const query = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`
